<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="6">Nombre : {{ NameUser }}</v-col>
    </v-row >
    <v-row justify="center">
      <v-col cols="6">Email : {{ EmailUser }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-subheader>
          Select School
        </v-subheader>
      </v-col>
      <v-col cols="8">
        <v-select
          :items="itemsEscuelas"
          item-text="name"
          item-value="id"
          label="School"
          solo
          v-model="SchoolSelected"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-subheader>
          Select Grade
        </v-subheader>
      </v-col>
      <v-col cols="8">
        <v-select
          :items="itemsGrados"
          label="Grade"
          solo
          v-model="GradeSelected"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="SchoolSelected!='' && GradeSelected!=''">
      <v-col>
        <v-hover
        v-slot="{ hover }"
        open-delay="100"
        >
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto d-flex align-center justify-center"
            height="200"
            max-width="350"
            color="#d0efff"
            @click="SelectedCourse(true)"
          >
            <v-card-title >
              Clase Bilingue
            </v-card-title>
          </v-card>
        </v-hover>
      </v-col>
      <v-col>
        <v-hover
        v-slot="{ hover }"
        open-delay="100"
        >
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto d-flex align-center justify-center"
            height="200"
            max-width="350"
            color="#d0fffc"
            @click="SelectedCourse(true)"
          >
            <v-card-title>
              Literatura
            </v-card-title>
          </v-card>
        </v-hover>
      </v-col>
      <v-col>
        <v-hover
        v-slot="{ hover }"
        open-delay="100"
        >
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto d-flex align-center justify-center"
            height="200"
            max-width="350"
            color="#e5ffee"
            @click="SelectedCourse(false)"
          >
            <v-card-title>
              Matematicas
            </v-card-title>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row justify="center" >
      <v-col cols="8" v-if="isAvailable == 'no'">
        <v-alert
          border="top"
          colored-border
          type="info"
          elevation="2"
        >
          Nuestra aplicación no esta disponible para su grado
        </v-alert>
      </v-col>
      <v-col cols="2" v-if="isAvailable == 'yes'">
        <v-btn
          tile
          color="success"
          x-large
          @click="CreateTeacher"
        >
          <v-icon left>
            mdi-pencil
          </v-icon>
          Register
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { functions, db, auth } from '../../plugins/firebase'

export default {
  name: 'Signup',
  props:['type'],
  data: () => ({
    NameUser: "",
    EmailUser:"",
    nameRules: [
      v => (v && v.length >= 2) || 'Minimum length is 2 characters',
      v => (v && v.length <= 30) || 'Name must be less than 30 characters'
    ],
    emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    itemsEscuelas: [],
    itemsGrados: [],
    SchoolSelected: "",
    GradeSelected:"",
    isAvailable: "-",
    idClever: '',
      access_token: '',
  }),
  created () {
    this.EnviarClever();
    this.getData();
  },
  methods: {
    async getData(){
      let escuelas = await db.doc('SchoolsData/RegionSchoolD/Countries/2r2JWPAq8NRItilttjH6/States/TpFpRZodvG40D5kmvE5z/SchoolDistricts/4dhXa83l2rr4k5rimGRO').get();
      console.log(escuelas.data());
      this.itemsEscuelas = escuelas.data().Schools2;
      let grados = await db.doc('SchoolsData/RegionSchoolD/Countries/2r2JWPAq8NRItilttjH6/GradeData/GradeD').get();
      this.itemsGrados = grados.data().GradeList;
    },
    SelectedCourse(activo){
      if (!activo) {
        this.isAvailable = "no";
        return;
      }
      this.isAvailable = 'yes'
    },
    GuardarMaestro(){

    },
    async EnviarClever () {
      try {
        let codeClever = this.$route.query.code;
        const loginClever = functions.httpsCallable('loginCleverDemo') // loginClever    --- loginCleverDemo
        await loginClever({ code: codeClever, url: 'http://localhost:8080/register-teacher' }) //https://apps.claseleelee.com/oauth2Demo     ---    http://localhost:8080/oauth2Demo
          .then((res) => {
            console.log(res) 
            if (res.data.answer) {
              this.access_token = res.data.tokenRes.access_token;
              this.idClever = res.data.idUserClever.data.id;
              this.GetDataUser()
              // this.GetDataSection()
            }else{
              console.log('error primero')
              // this.$router.push({path: '/errorLog'})
            }
          })
      } catch (error) {
        console.log(error)
        this.$router.push({path: '/errorLog'})
      }
    },
    GetDataUser () {
      const url = 'https://api.clever.com/v3.0/users/' + this.idClever;
      const options = {
        method: 'GET',
        headers: {Accept: 'application/json', Authorization: 'Bearer ' + this.access_token}
      };

      fetch(url, options)
        .then(res => res.json())
        .then(json => {
          let role = Object.keys(json.data.roles);
          console.log(json)
          console.log(role)
          if(role[0] != "teacher"){
            this.$router.push({path: '/errorLog'})
          }
          this.NameUser = json.data.name['first'] + json.data.name['last'];
          this.EmailUser = json.data.email;

        })
        .catch(err => console.error('error:' + err));
    },
    async CreateTeacher() {
      let localStore =this.$store;
      try {
        auth().signOut()
        localStorage.removeItem('email');
        localStorage.removeItem('uid');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        const VerifyLogin = functions.httpsCallable('VerifyLoginTeacher')
        await VerifyLogin({ access_token: this.access_token, idClever: this.idClever, email: this.EmailUser })
          .then((res) => {
            //console.log(res)
            if (Object.prototype.hasOwnProperty.call(res.data, 'error')) 
            {
              console.log('ocurrio un error')
              this.$router.push({path: '/errorLog'})
              return;
            }
            if (Object.prototype.hasOwnProperty.call(res.data, 'token')) {
              if ((!res.data.crear) && (!res.data.user) ) {
                console.log('ocurrio un error')
              this.$router.push({path: '/errorLog'})
              return;
              }
              auth().signInWithCustomToken(res.data.token)
                .then((userCredential) => {
                  //guardamos token para seccion
                  db.collection("TempTokensClever").doc(this.idClever).set({ date: Date.now(), token: this.access_token })
                  //
                  //localStorage.setItem('token2', res.data.token)
                  if (res.data.crear) {
                    //aqui creamos
                    console.log('creamos')
                    userCredential.user.updateEmail(this.email);
                    const createCustomUser = functions.httpsCallable('createCustomUserClever')
                    createCustomUser({ uid: this.idClever, email: this.email, firstName: this.firstName, lastName: this.lastName }).then((respues) => {
                      console.log(respues)
                      console.log('termino de crear');
                      this.LoginStoreClever(true);
                    })
                  } else {
                    //logueamos
                    this.LoginStoreClever(false);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });
            }
          })
      } catch (error) {
        console.log(error)
        this.$router.push({path: '/errorLog'})
      }
    },
    LoginStoreClever (nuevo) {
      this.$store.dispatch('loginClever', nuevo).then(() => {
        // TODO - verificar los custom claims
        this.$router.push({path: '/login'})
      }).catch(error => {
        console.log(error);
        this.errorMessage = error;
      });
    },
  }
}
</script>
<style>

</style>